<template>
  <span :class="`status status--${status}`">{{ statuses[status] }}</span>
</template>


<script>
export default {
  props: ['status'],
  data() {
    return {
      statuses: {
        unfinished: 'ПРЕДСТОЯЩО',
        canceled: 'ОТМЕНЕНО',
        finished: 'ЗАВЪРШЕНО'
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.status {
  align-items: center;
  border-radius: 2px;
  color: #000;
  display: inline-flex;
  font-size: 0.85rem;
  font-weight: 700;
  height: 22px;
  justify-content: center;
  width: 150px;
  &.status--unfinished {
    background-color: #ffd966;
  }
  &.status--canceled {
    background-color: #f4b084;
  }
  &.status--finished {
    background-color: #a9d08e;
  }
}
</style>