<template>
  <div class="examinations">
    <div class="title-and-buttons">
      <div class="title">
        <h1>Направления и резултати от рентгенографии</h1>
        <p class="accent">
          Имате {{ total }}
          <template v-if="total === 1">издадено направление</template>
          <template v-else>издадени направления</template>
        </p>
      </div>
    </div>
    <div class="table-wrapper">
      <p v-if="!loaded" class="center">Зареждане...</p>
      <table class="table" v-if="examinations.length">
        <thead>
          <tr>
            <th class="center">
              ДАТА
              <br />ЗАЯВЛЕНИЕ
            </th>
            <th class="center">
              ДАТА
              <br />ИЗСЛЕДВАНЕ
            </th>
            <th>НАПРАВЛЕНИЕ ОТ</th>
            <th>ЗА ПАЦИЕНТ</th>
            <th>ТИП ИЗСЛЕДВАНЕ</th>
            <th>БР.СНИМКИ</th>
            <th class="center">
              <div class="status-filter">
                <header>
                  СТАТУС
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.001"
                    height="10.5"
                    viewBox="0 0 20.001 10.5"
                  >
                    <path
                      id="Path_22160"
                      data-name="Path 22160"
                      d="M12.727,3.687a1,1,0,0,0-1.454-1.373l-8.5,9a1,1,0,0,0,0,1.373l8.5,9a1,1,0,1,0,1.454-1.373L4.875,12Z"
                      transform="translate(-2 13) rotate(-90)"
                      fill="#212121"
                    />
                  </svg>
                </header>
                <main>
                  <p
                    class="all"
                    :class="{ active: !filter }"
                    @click="selectFilter('')"
                  >
                    ВСИЧКИ
                  </p>
                  <p
                    class="finished"
                    :class="{ active: !filter || filter === 'finished' }"
                    @click="selectFilter('finished')"
                  >
                    ЗАВЪРШЕНИ
                  </p>
                  <p
                    class="unfinished"
                    :class="{ active: !filter || filter === 'unfinished' }"
                    @click="selectFilter('unfinished')"
                  >
                    ПРЕДСТОЯЩИ
                  </p>
                  <p
                    class="canceled"
                    :class="{ active: !filter || filter === 'canceled' }"
                    @click="selectFilter('canceled')"
                  >
                    ОТМЕНЕНИ
                  </p>
                </main>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="e in examinations"
            :key="e._id"
            :class="{ 'row--selected': selectedExamination === e._id }"
            @click="selectExamination(e)"
          >
            <td class="center">{{ e.createdAt | formatDate }}</td>
            <td class="center">
              <span v-if="e.canceled">-</span>
              <span v-else-if="typeof e.examinationDate === 'undefined'"
                >Зареждане...</span
              >
              <span v-else>{{ e.examinationDate | formatDate }}</span>
            </td>
            <td v-if="e.createdBy">{{ e.createdBy.name }}</td>
            <td v-else>Автоматично</td>
            <td>{{ e.user ? e.user.name : "" }}</td>
            <td>
              <p>{{ e.name }}</p>
              <p class="sub">{{ e.categories }}</p>
            </td>
            <td>{{ e.photos }}</td>
            <td class="center">
              <span v-if="!e.status">Зареждане...</span>
              <Status v-else :status="e.status" />
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination :total="total" :size="size" />
    </div>
    <div class="help-image" v-if="loaded && currentUser.role !== 'pacient'">
      <img
        src="@/assets/img/help/helpExaminations.svg"
        alt="Стъпки"
        v-if="!examinations.length"
      />
      <img src="@/assets/img/help/helpExaminationsS.svg" alt="Стъпки" v-else />
    </div>
    <ExaminationPanel
      v-if="isExaminationPanelOpen"
      @close-panel="examinationPanelClosed"
      :id="selectedExamination"
      @refresh-examinations="getExaminations()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Pagination from "@/components/Pagination";
import Status from "@/components/Status";
import ExaminationPanel from "@/views/panels/ExaminationPanel";
export default {
  computed: {
    ...mapGetters(["currentUser"]),
  },
  components: {
    Pagination,
    ExaminationPanel,
    Status,
  },
  watch: {
    $route() {
      this.getExaminations();
    },
  },
  data() {
    return {
      isExaminationPanelOpen: false,
      selectedExamination: null,
      total: 0,
      size: 20,
      examinations: [],
      loaded: false,
      ws: null,
      filter: "",
    };
  },
  methods: {
    selectExamination(e) {
      if (e.canceled) {
        this.$snotify.error(
          "Направлението е отменено и не може да бъде отваряно."
        );
      } else {
        this.selectedExamination = e._id;
        this.isExaminationPanelOpen = true;
      }
    },
    examinationPanelClosed(update) {
      this.isExaminationPanelOpen = false;
      this.selectedExamination = null;
      if (update) {
        this.getExaminations();
      }
    },
    getExaminations() {
      this.loaded = false;
      this.examinations = [];

      let url = `/examinations?size=${this.size}&page=${
        this.$route.query.page || 1
      }`;

      if (this.filter) {
        url += `&filter=${this.filter}`;
      }

      this.$apiService.get(url).then((res) => {
        this.examinations = res.data.examinations;
        this.total = res.data.total;

        this.examinations.map((e) => {
          if (e.canceled) {
            this.$set(e, "status", "canceled");
          } else {
            if (e.photos) {
              this.$apiService
                .get(`/photos/examination/status/${e._id}`)
                .then((res) => {
                  this.$set(e, "examinationDate", res.data.date);
                  this.$set(e, "categories", res.data.categories);
                });
            } else {
              this.$set(e, "examinationDate", null);
            }
            this.$set(e, "status", e.photos ? "finished" : "unfinished");
          }
        });

        this.loaded = true;
      });
    },
    selectFilter(f) {
      this.filter = f;
      this.getExaminations();
    },
  },
  mounted() {
    this.getExaminations();

    if (this.$route.params.id) {
      this.selectExamination({ _id: this.$route.params.id });
    }

    const p = window.location.protocol === "https:" ? "wss" : "ws";
    this.ws = new WebSocket(
      `${p}://${
        process.env.VUE_APP_ENV === "dev"
          ? `${window.location.hostname}:4004`
          : window.location.host
      }`
    );

    if (this.ws) {
      this.ws.onmessage = (message) => {
        const m = JSON.parse(message.data);
        if (m.name === "new-examination") {
          this.getExaminations();
        }
      };
    }
  },
  destroyed() {
    if (this.ws) {
      this.ws.close();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";

.status-filter {
  cursor: pointer;
  align-items: center;
  // display: inline-flex;
  display: flex;
  justify-content: center;
  position: relative;
  height: 28px;

  header {
    svg {
      margin-left: 2px;
      width: 12px;
    }
  }

  main {
    // background-color: #416184;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.5);
    background-color: #fff;
    color: #fff;
    display: none;
    left: 50%;
    position: absolute;
    top: 100%;
    // width: 100%;
    @include transform(translateX(-50%));
    @include noselect();

    p {
      background-color: #bdbdbd;
      padding: 0.5rem 1rem;

      &:hover {
        // background-color: rgba($color: #fff, $alpha: 0.1);
        opacity: 0.8;
      }
      &.active {
        &.all {
          background-color: #000000;
        }
        &.unfinished {
          background-color: #ffb300;
        }
        &.canceled {
          background-color: #e96109;
        }
        &.finished {
          background-color: #53a11d;
        }
        // background-color: rgba($color: #fff, $alpha: 0.25);
      }
    }
  }

  &:hover {
    main {
      display: block;
    }
  }
}

.help-image {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 100%;
  img {
    height: auto;
    max-width: 100%;
  }
}

@media screen and (max-width: $sm) {
  .help-image {
    img {
      height: auto;
      width: 100%;
    }
  }
}

@media print {
  .examinations {
    .title-and-buttons,
    .table-wrapper {
      display: none;
    }
    .help-image {
      display: none;
    }
  }
}
</style>