<template>
  <div class="pagination" v-if="total >= size">
    <!-- FIRST PAGE -->
    <span class="pagination__item" v-if="page > 3" @click="changePage(1)">
      <span class="icon">l</span>
    </span>
    <span class="pagination__item" v-if="page > 3" @click="changePage(page - 1)">
      <span class="icon">m</span>
    </span>
    <span class="pagination__item" v-if="page > 2" @click="changePage(page - 2)">{{ page - 2 }}</span>
    <span class="pagination__item" v-if="page > 1" @click="changePage(page - 1)">{{ page - 1 }}</span>
    <span class="pagination__item pagination__current">{{page}}</span>
    <span
      class="pagination__item"
      v-if="page <  Math.ceil(total / size) - 1"
      @click="changePage(page + 1)"
    >{{ page + 1 }}</span>
    <span
      class="pagination__item"
      v-if="page <  Math.ceil(total / size) - 2"
      @click="changePage(page + 2)"
    >{{ page + 2 }}</span>
    <span
      class="pagination__item"
      v-if="page <  Math.ceil(total / size) - 2"
      @click="changePage(page + 1)"
    >
      <span class="icon">o</span>
    </span>
    <!-- LAST PAGE -->
    <span
      class="pagination__item"
      v-if="page !== Math.ceil(total / size) && Math.ceil(total / size)"
      @click="changePage(Math.ceil(total / size))"
    >
      <span class="icon" v-if="page < Math.ceil(total / size) - 2">n</span>
      <template v-else>{{ Math.ceil(total / size) }}</template>
    </span>
  </div>
</template>

<script>
export default {
  props: ['total', 'size'],
  computed: {
    page() {
      return parseInt(this.$route.query.page) || 1;
    }
  },
  methods: {
    changePage(p) {
      this.$router.push({ path: this.$route.path, query: { page: p } });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  width: 100%;
  .pagination__item {
    align-items: center;
    background-color: #f8f8f8;
    color: $color;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    height: $input-height;
    margin: 0 2px;
    min-width: $input-height;
    @include noselect();
    @include hover();
    &.pagination__dots {
      opacity: 0.2;
    }
    &.pagination__current {
      @include gradient();
      // background-color: #0077c7;
      color: #fff;
    }
  }
}
</style>
